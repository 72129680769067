import React from 'react'
import { Link, LinkProps } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { DOPPEL_LINK } from '@/utils/style'
import { prepareUrl } from '@/utils/domain_utils'

type Props = {
  link: string
  name?: string
} & LinkProps

const LinkExternal: React.FC<Props> = ({ link, name, ...rest }) => {
  return (
    <Link color={DOPPEL_LINK} href={prepareUrl(link)} isExternal {...rest}>
      {/* workaround: render the icon as a link, since URL is shown in dropdown */}

      {name ? name : <ExternalLinkIcon ml={2} />}
    </Link>
  )
}

export default LinkExternal
