// component

import { Divider, Flex, Text } from '@chakra-ui/react'
import { ExternalSourceTagWithMenu } from '../external_source_tag_with_menu'
import DetailRow from '../../report_detail/detail_row'
import { useSelectedSpoofReport } from '../../../hooks/spoof_report_table_filters'
import { useIsEmployeeView } from '../../../hooks/id_token_claims'
import { getExternalUser } from '../../../utils/reports/report_utils'

export default function SpoofDetailUploader() {
  const [isEmployeeView] = useIsEmployeeView()
  const [selectedSpoofReport] = useSelectedSpoofReport()

  if (!selectedSpoofReport?.uploader) return <></>

  const uploaderName = getExternalUser(selectedSpoofReport?.uploader, isEmployeeView)

  return (
    <>
      <Divider maxWidth="90%" />

      <DetailRow
        hasMaxTwoColumns={true}
        leftDetail={
          <Flex>
            <Text fontSize="xs" pr="2">
              {uploaderName}
            </Text>

            <ExternalSourceTagWithMenu />
          </Flex>
        }
        title="Uploaded By"
      />
    </>
  )
}
