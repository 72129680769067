import { useSelectedSpoofReport } from '../../../hooks/spoof_report_table_filters'
import { ProductType, HASURA_MAX_INT } from '../../../utils/constants'
import DetailRow from '../../report_detail/detail_row'
import { Divider, Text } from '@chakra-ui/react'

export default function SpoofDetailEcommerceBreakdown() {
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const product = selectedSpoofReport.platform?.product
  if (
    product === ProductType.DARK_MARKET ||
    selectedSpoofReport.platform?.name === 'roblox'
  ) {
    const ecommerceListing = extractEcommerceListing(selectedSpoofReport)

    return (
      <>
        {ecommerceListing?.price && (
          <>
            <Divider maxWidth="90%" />

            <DetailRow
              hasMaxTwoColumns={true}
              leftDetail={
                <Text fontSize={13}>
                  {ecommerceListing?.price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Text>
              }
              title="Price"
            />
          </>
        )}

        {ecommerceListing && ecommerceListing?.num_units !== null && (
          <>
            <Divider maxWidth="90%" />

            <DetailRow
              hasMaxTwoColumns={true}
              leftDetail={
                <Text fontSize={13}>
                  {ecommerceListing?.num_units == HASURA_MAX_INT
                    ? '∞'
                    : String(ecommerceListing?.num_units)}
                </Text>
              }
              title="Number of Units"
            />
          </>
        )}

        {ecommerceListing?.title && (
          <>
            <Divider maxWidth="90%" />

            <DetailRow
              hasMaxTwoColumns={true}
              leftDetail={<Text fontSize={13}>{ecommerceListing?.title}</Text>}
              title="Listing Title"
            />
          </>
        )}

        {ecommerceListing?.seller_name && (
          <>
            <Divider maxWidth="90%" />

            <DetailRow
              hasMaxTwoColumns={true}
              leftDetail={<Text fontSize={13}>{ecommerceListing?.seller_name}</Text>}
              title="Seller Name"
            />
          </>
        )}

        {ecommerceListing?.description && (
          <>
            <Divider maxWidth="90%" />

            <DetailRow
              hasMaxTwoColumns={true}
              leftDetail={<Text fontSize={13}>{ecommerceListing?.description}</Text>}
              title="Description"
            />
          </>
        )}

        {ecommerceListing?.telegram_info?.inviteLink && (
          <>
            <Divider maxWidth="90%" />

            <DetailRow
              hasMaxTwoColumns={true}
              leftDetail={
                <Text fontSize={13}>{ecommerceListing?.telegram_info?.inviteLink}</Text>
              }
              title="Telegram Link"
            />
          </>
        )}
      </>
    )
  }

  return null
}

const extractEcommerceListing = (selectedSpoofReport) => {
  if (selectedSpoofReport.ecommerce_listing)
    return selectedSpoofReport.ecommerce_listing

  if (selectedSpoofReport.spoof_matches.length > 0)
    return selectedSpoofReport.spoof_matches[0].ecommerce_listing

  return null
}
