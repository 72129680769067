import DoppelAlertDialog from '@/components/shared/doppel_alert_dialog'
import { useReroutePlatformSubtypeMenuListItems } from '@/components/tables/bulk_reroute_platform_subtype_button'
import { ReportChangeEventSource } from '@/generated/enums'
import { useUpdateSpoofingReportsMutation } from '@/generated/graphql'
import { delayRefetchedQueries } from '@/utils'
import { DOPPEL_BUTTON_GREY, DOPPEL_WHITE } from '@/utils/style'
import { useApolloClient } from '@apollo/client'
import { EditIcon } from '@chakra-ui/icons'
import {
  useToast,
  useDisclosure,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { useState, useRef, useEffect } from 'react'

export default function SpoofDetailReroutePlatformSubtypeButton({ alert }) {
  const [updateSpoofingReportsMutation, { data, loading, error }] =
    useUpdateSpoofingReportsMutation()
  const [platformSubtypeToReroute, setPlatformSubtypeToReroute] = useState<any>()
  const cancelRef = useRef()
  const toast = useToast()
  const client = useApolloClient()

  const handlePlatformSubtypeSelect = (subtype) => {
    setPlatformSubtypeToReroute(subtype)
    onOpen()
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    if (data) {
      toast({
        title: 'Success!',
        status: 'success',
        isClosable: true,
      })
    } else if (error) {
      toast({
        title: 'Error',
        status: 'error',
        isClosable: true,
      })
    }
  }, [data, error])

  const confirmReroute = () => {
    if (platformSubtypeToReroute) {
      // TODO (table view refactor): use update report service

      updateSpoofingReportsMutation({
        variables: {
          input: {
            report_ids: [alert.id],
            update_source: ReportChangeEventSource.UI,
            platform_subtype_id: platformSubtypeToReroute.id,
          },
        },
        update: (cache, { data: { update_reports_action } }) => {
          // this cache update works, but fails to remove the report from the query list
          cache.modify({
            id: cache.identify(alert),
            fields: {
              platform_subtype: () => platformSubtypeToReroute,
            },
          })
        },
      }).then(() => {
        delayRefetchedQueries(client, ['SearchSpoofingReports'])
      })
      onClose()
    }
  }
  const menuListItems = useReroutePlatformSubtypeMenuListItems(
    handlePlatformSubtypeSelect,
  )

  return (
    <>
      <Menu closeOnSelect={false}>
        <MenuButton
          aria-label={''}
          as={IconButton}
          bgColor={DOPPEL_BUTTON_GREY}
          color={DOPPEL_WHITE}
          icon={<EditIcon />}
          size="xs"
        ></MenuButton>

        <MenuList maxH="300px" overflowY="auto">
          {menuListItems.map((item, index) => (
            <React.Fragment key={item.key || index}>{item.content}</React.Fragment>
          ))}
        </MenuList>
      </Menu>

      <DoppelAlertDialog
        body={
          <>
            <Text>
              Are you sure you want to reroute this report ({alert.flagged_url}) to the
              platform {platformSubtypeToReroute?.name}?
            </Text>

            <Text mt={1}>
              This is a new feature. If an area of the app doesn&apos;t update properly,
              please let us know in #product-support, and try rerouting the platform
              using BOTH this button and the &ldquo;Bulk Reroute Platform (Old)&rdquo;
              button.
            </Text>
          </>
        }
        cancelRef={cancelRef}
        confirmAction={confirmReroute}
        header="Reroute Platform"
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
