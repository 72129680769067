import { Button, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import { useSelectedSpoofReport } from '../../hooks/spoof_report_table_filters'
import {
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_LIGHT_GREY,
  DOPPEL_WHITE,
} from '../../utils/style'
import { usePublishRequestSpoofScreenshotMutation } from '../../generated/graphql'
import { FaCamera } from 'react-icons/fa'

export default function SpoofRequestScreenshotButton() {
  const [isEmployeeView] = useIsEmployeeView()
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [publishRequestSpoofScreenshotMutation] =
    usePublishRequestSpoofScreenshotMutation()

  useEffect(() => {
    if (selectedSpoofReport?.id) {
      setLoading(false)
    }
  }, [selectedSpoofReport?.id])

  if (!isEmployeeView) {
    // don't expose this to customers yet
    return <></>
  }
  return (
    <Button
      _hover={{ background: DOPPEL_LIGHT_GREY }}
      aria-label=""
      bgColor={DOPPEL_INTERNAL_PURPLE}
      borderRadius="500px"
      color={DOPPEL_WHITE}
      disabled={loading}
      isLoading={loading}
      onClick={() => {
        setLoading(true)

        publishRequestSpoofScreenshotMutation({
          variables: {
            report_id: selectedSpoofReport.id,
          },

          onCompleted: (data) => {
            setLoading(false)
            const screenshotAlreadyExists =
              data.publish_request_spoof_screenshot?.screenshot_url_already_exists
            const matches = data.publish_request_spoof_screenshot?.matches_published
            const screenshotUrl =
              data.publish_request_spoof_screenshot?.report_screenshot_url
            let title = 'Did Not Screenshot Alert'
            let description =
              'Matches submitted: ' +
              matches.toString() +
              '\n' +
              'Screenshot URL: ' +
              data.publish_request_spoof_screenshot?.report_screenshot_url
            if (screenshotUrl != null && screenshotAlreadyExists) {
              title = 'Screenshot Already Present'
            }
            if (screenshotUrl != null && !screenshotAlreadyExists) {
              title = 'Screenshot Generated'
            }
            if (screenshotUrl == null) {
              description =
                'Contact the doppel team to see why screenshots are blocked for this alert type'
            }
            toast({
              title: title,
              description: description,
              status: 'success',
              isClosable: true,
            })
          },
          onError: (error) => {
            toast({
              title: "Couldn't request screenshot.",
              description: error.message,
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          },
        })
      }}
      size={'sm'}
    >
      <FaCamera />
    </Button>
  )
}
