import { useSelectedSpoofReport } from '../../../hooks/spoof_report_table_filters'
import SocialMediaDataBreakdown from './social_media_data_breakdown'
import SocialMediaPostBreakdown from './social_media_post_breakdown'

export default function SocialMediaBreakdown() {
  const [selectedSpoofReport, setSelectedSpoofReport] = useSelectedSpoofReport()
  const socialMediaData =
    selectedSpoofReport.social_media_data ||
    selectedSpoofReport.spoof_matches[0]?.social_media_data
  const socialMediaPost =
    selectedSpoofReport.social_media_post ||
    selectedSpoofReport.spoof_matches[0]?.social_media_post

  if (socialMediaData) {
    return SocialMediaDataBreakdown(socialMediaData)
  } else if (socialMediaPost) {
    return SocialMediaPostBreakdown(socialMediaPost)
  } else {
    return null
  }
}
