import React, { useState, useEffect } from 'react'
import {
  Box,
  CloseButton,
  Divider,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { RiRobotLine, RiThumbUpFill, RiThumbDownFill } from 'react-icons/ri'
import {
  useGenerateAiRecommendationsLazyQuery,
  useGetAiPromptsForDefaultActionQuery,
  useRecordAiResponseFeedbackVotesMutation,
} from '@/generated/graphql'
import {
  AIPromptMetadataKey,
  AIPromptRole,
  AIPromptType,
  ReportActionsLabels,
} from '@/generated/enums'
import {
  DOPPEL_BLACK,
  DOPPEL_GREY,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_LIGHT_GREY,
  DOPPEL_WHITE,
} from '@/utils/style'

type AiSuggestionsButtonProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedSpoofReport: any
  isEmployeeView: boolean
  platform: string
}

const AiSuggestionsButton: React.FC<AiSuggestionsButtonProps> = ({
  selectedSpoofReport,
  isEmployeeView,
  platform,
}) => {
  const promptsForPlatform = useGetAiPromptsForDefaultActionQuery({
    variables: {
      platform: platform,
      type: AIPromptType.REPORT_ACTION_SUGGESTION,
      role: AIPromptRole.USER,
      sub_type: AIPromptMetadataKey.BASE_PROMPT,
    },
  })

  const showAISuggestionsButton =
    isEmployeeView && platform && promptsForPlatform.data?.ai_prompts.length > 0
  const [aiRecommendationDetails, setAiRecommendationDetails] = useState([])
  const [aiRecommendationTitle, setAiRecommendationTitle] = useState('')
  const [responseId, setResponseId] = useState(null)
  const [feedbackRecorded, setFeedbackRecorded] = useState(false)
  const [generateAiRecommendations, { loading: generateSuggestionsLoading }] =
    useGenerateAiRecommendationsLazyQuery()
  const [recordFeedback, { loading: recordFeedbackLoading }] =
    useRecordAiResponseFeedbackVotesMutation()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const handleFeedback = async (isHelpful) => {
    if (feedbackRecorded) return
    const feedbackResponse = await recordFeedback({
      variables: {
        id: responseId,
        upvotes: isHelpful ? 1 : 0,
        downvotes: isHelpful ? 0 : 1,
      },
    })
    setFeedbackRecorded(true)
    const errors = feedbackResponse?.errors
    const errorMessages = errors?.map((error) => error.message)
    if (errors) {
      toast({
        title: 'Error recording feedback',
        description: errorMessages.join('\n'),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      })
    } else {
      toast({
        title: 'Feedback recorded',
        description: 'Thank you for your feedback!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      })
    }
  }

  useEffect(() => {
    setAiRecommendationTitle('')
    setAiRecommendationDetails([])
    setFeedbackRecorded(false)
    onClose()
  }, [selectedSpoofReport])

  useEffect(() => {
    if (aiRecommendationDetails.length && aiRecommendationTitle && isOpen) {
      onOpen()
    }
  }, [
    aiRecommendationDetails,
    aiRecommendationTitle,
    feedbackRecorded,
    recordFeedbackLoading,
  ])

  const handleGenerateAiRecommendations = async () => {
    if (isOpen) {
      onClose()
    }
    const recommendationsResponse = await generateAiRecommendations({
      variables: {
        report_id: selectedSpoofReport.id,
        AI_suggestion_type: AIPromptType.REPORT_ACTION_SUGGESTION,
      },
    })
    const error = recommendationsResponse?.error?.message
    if (error) {
      toast({
        title: 'Error generating AI suggestions',
        description: error,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      })
      setFeedbackRecorded(false)
      return
    } else {
      const recommendation =
        recommendationsResponse?.data?.generate_ai_recommendations?.recommendations
      if (recommendation) {
        setAiRecommendationTitle(recommendation?.suggested_action)
        setAiRecommendationDetails(recommendation?.reasons)
      }
      const responseId =
        recommendationsResponse?.data?.generate_ai_recommendations?.response_id
      if (responseId) {
        setResponseId(responseId)
      }
    }
    onOpen()
  }

  if (!showAISuggestionsButton) return <></>

  return (
    <>
      <Tooltip
        hasArrow
        label="Generate alert suggestions. Use with caution, LLM usage is expensive"
        placement="left"
      >
        <IconButton
          _hover={{ background: DOPPEL_GREY }}
          aria-label="internal ai suggestion"
          bgColor={DOPPEL_INTERNAL_PURPLE}
          borderRadius="500px"
          color="white"
          icon={<RiRobotLine />}
          isLoading={generateSuggestionsLoading}
          onClick={handleGenerateAiRecommendations}
          size={'sm'}
        />
      </Tooltip>

      {isOpen && (
        <Box
          bg={DOPPEL_INTERNAL_PURPLE}
          borderRadius="md"
          bottom={10}
          boxShadow="lg"
          color={DOPPEL_WHITE}
          left="50%"
          maxWidth="600px"
          p={4}
          position="fixed"
          transform="translateX(-50%)"
          zIndex={1300}
        >
          <HStack justifyContent="space-between">
            <Text fontSize="lg" fontWeight="bold">
              AI Suggested Action
            </Text>

            <CloseButton color={DOPPEL_WHITE} onClick={onClose} />
          </HStack>

          <Text mt={2}>{ReportActionsLabels[aiRecommendationTitle]}</Text>

          <Text mt={2}>
            {aiRecommendationDetails.map((line, index) => (
              <React.Fragment key={index}>
                {' - '}

                {line}

                {index < aiRecommendationDetails.length - 1 && <br />}
              </React.Fragment>
            ))}
          </Text>

          <Divider borderColor={DOPPEL_BLACK} mt={4} />

          <Box pt={4}>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Was this suggestion helpful?
            </Text>

            <HStack>
              <IconButton
                _hover={{ background: DOPPEL_LIGHT_GREY }}
                aria-label="helpful"
                bgColor="green.500"
                borderRadius="500px"
                color="white"
                disabled={feedbackRecorded}
                icon={<RiThumbUpFill />}
                isLoading={recordFeedbackLoading}
                onClick={() => handleFeedback(true)}
                size={'sm'}
              />

              <IconButton
                _hover={{ background: DOPPEL_LIGHT_GREY }}
                aria-label="not helpful"
                bgColor="red.500"
                borderRadius="500px"
                color="white"
                disabled={feedbackRecorded}
                icon={<RiThumbDownFill />}
                isLoading={recordFeedbackLoading}
                onClick={() => handleFeedback(false)}
                size={'sm'}
              />
            </HStack>
          </Box>
        </Box>
      )}
    </>
  )
}

export default AiSuggestionsButton
