import { HStack, Spinner, Tag } from '@chakra-ui/react'
import { DOPPEL_BLACK, DOPPEL_GREY } from '../../utils/style'
import EditDropdown from '../shared/edit_dropdown'
import { ExternalSource } from '../../utils/constants'
import { useUpdateExternalSourceForReportMutation } from '../../generated/graphql'
import { useSelectedSpoofReport } from '../../hooks/spoof_report_table_filters'
import { getOrgData, useOrgID, useUserID } from '../../hooks/id_token_claims'
import { isExternalSourceEnabled } from '../../utils/feature_flags'
import { DoppelTag } from '../shared/doppel_tag'

export function ExternalSourceTagWithMenu() {
  const orgId = useOrgID()
  const [orgData] = getOrgData()
  const [updateExternalSourceForReportMutation, { loading }] =
    useUpdateExternalSourceForReportMutation()
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [userId] = useUserID()

  if (!isExternalSourceEnabled(orgId)) {
    return <></>
  }
  const externalSource = selectedSpoofReport.external_source as ExternalSource

  let externalSourceToDisplay: string = externalSource
  if (
    externalSource == null &&
    selectedSpoofReport?.uploader != null &&
    !selectedSpoofReport?.uploader?.is_internal
  ) {
    externalSourceToDisplay = orgData.name
  }
  if (externalSourceToDisplay == null) {
    externalSourceToDisplay = 'Doppel'
  }

  return (
    <HStack spacing={2}>
      <DoppelTag
        tag={externalSourceToDisplay}
        bgColor={DOPPEL_GREY}
        color={DOPPEL_BLACK}
      />

      <EditDropdown
        currentValue={externalSource}
        onChange={(value: ExternalSource) => {
          updateExternalSourceForReportMutation({
            variables: {
              id: selectedSpoofReport.id,
              external_source: value,
              report_change: {
                type: 'spoof_status',
                spoof_report_id: selectedSpoofReport.id,
                new_value: value,
                old_value: selectedSpoofReport.external_source,
                user_id: userId,
                source: 'ui',
              },
            },
          })
        }}
        values={Object.values(ExternalSource)}
      />
    </HStack>
  )
}
