import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { DarkWebPlatforms } from '@/utils/constants'
import { useAtom } from 'jotai'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import { useSelectedSpoofReport } from '@/hooks/spoof_report_table_filters'

export default function DarkWebCreditCardInfo() {
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const credit_card_data =
    selectedSpoofReport?.spoof_matches?.[0]?.dark_web?.credit_card || {}

  if (
    selectedReportExposureType !== DarkWebPlatforms.CREDIT_CARD_LEAKS &&
    selectedSpoofReport.type !== DarkWebPlatforms.CREDIT_CARD_LEAKS
  ) {
    return <></>
  }

  const {
    credit_card_number,
    bin,
    expiration_date,
    cardholder_name,
    cardholder_address,
  } = credit_card_data
  const formattedExpirationDate = expiration_date?.slice(0, 7)

  return (
    <Box my={4} p={6}>
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Credit Card Information
      </Text>

      <Box mt={4} paddingLeft={5}>
        <Text fontSize="md" mb={2}>
          <strong>Bank Identification Number: </strong>

          {bin}
        </Text>

        <Text fontSize="md" mb={2}>
          <strong>Card Number: </strong>

          {credit_card_number || 'Paywalled'}
        </Text>

        <Text fontSize="md" mb={2}>
          <strong>Expiration Date: </strong>

          {formattedExpirationDate}
        </Text>

        <Text fontSize="md" mb={2}>
          <strong>Cardholder Name: </strong>

          {cardholder_name || 'N/A'}
        </Text>

        <Text fontSize="md" mb={2}>
          <strong>Cardholder Address: </strong>

          {cardholder_address || 'N/A'}
        </Text>
      </Box>
    </Box>
  )
}
