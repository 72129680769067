import React, { useEffect } from 'react'
import { withApollo } from '@apollo/react-hoc'
import { CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Tag,
  Text,
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { IoMdEye } from 'react-icons/io'
import {
  useGetEnforcementRequestsForSpoofingReportQuery,
  useGetReportChangesQuery,
  useUpdateSpoofingReportsMutation,
  useGetTagsByReportQuery,
  useGetUploadedFilesQuery,
  useGetAlertLinksByAlertIdQuery,
} from '@/generated/graphql'
import { useIsEmployeeView } from '@/hooks/id_token_claims'
import { useSelectedSpoofReport } from '@/hooks/spoof_report_table_filters'
import { useSelectedReportType } from '@/pages/reports'
import { delayRefetchedQueries } from '@/utils'
import { ProductType } from '@/generated/enums'
import {
  Classification,
  getStatusLabel,
  ReportStatus,
  ReportType,
  STATUS_TO_HEADER_LABEL,
} from '@/utils/constants'
import { ReportChangeEventSource } from '@/generated/enums'
import { getEnforcementPlatformOptions } from '@/utils/enforcement_utils'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_LIGHT_GREY } from '@/utils/style'
import { getTimestampDisplay } from '@/utils/time'
import EnforcementModal from '../../enforcements/enforcement_modal'
import DetailRow from '../../report_detail/detail_row'
import ReportDetailButton, {
  getAllowedActionButtons,
} from '../../report_detail/report_detail_button'
import DetailNavBar from '../../shared/back_button'
import DetailNotes from '../../shared/detail_notes'
import DetailModal from '../../shared_reports_table/detail_modal'
import { AuditLogTable } from '../audit_logs/audit_log_table'
import SocialMediaBreakdown from '../social_media/social_media_breakdown'
import SpoofRequestScreenshotButton from '../spoof_request_screenshot_button'
import { SpoofStatusTagWithMenu } from '../spoof_status_tag_with_menu'
import SpoofDetailDarkWebBreakdown from './spoof_detail_dark_web_breakdown'
import SpoofDetailDarkWebPreview from './spoof_detail_dark_web_preview'
import SpoofDetailDomainData from './spoof_detail_domain_data'
import SpoofDetailImagePreview from './spoof_detail_image_preview'
import SpoofDetailDebugView from './spoof_detail_debug_view'
import EnforcementStatuses from '../../enforcements/enforcement_statuses'
import SpoofDetailEcommerceBreakdown from './spoof_detail_ecommerce_breakdown'
import { useToast } from '@chakra-ui/react'
import SpoofDetailUploader from './spoof_detail_uploader'
import DownloadTdFile from '@/components/shared/download_td_file'
import { DoppelTag } from '../../shared/doppel_tag'
import { toSnakeCase } from '@/utils/string_utils'
import EnforcementDetailModal from '@/components/enforcements/enforcement_detail_modal'
import { DetectionReasonsTable } from '../detection_reasons/detection_reasons_table'
import AiSuggestionsButton from './ai_suggestions_button'
import { UploadedFilesTable } from '../report_files/uploaded_files_table'
import SpoofDetailEditTagsButton from './spoof_detail_edit_tags_button'
import { DARK_WEB_TYPES, getExternalReportId } from '@/utils/reports/report_utils'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import { useAtom } from 'jotai'
import SpoofChangeBrandButton from './spoof_detail_change_brand_button'
import AlertLinksTable from '@/components/alert_links/alert_links_table'
import { convertToInternalAlertLinks } from '@/components/alert_links/alert_link'
import { getColorForSeverity } from '../spoof_status_tag_with_menu'
import RetractionModal from '@/components/enforcements/retractions/retraction_modal'
import SpoofDetailReroutePlatformSubtypeButton from './spoof_detail_reroute_platform_subtype_button'
import { getPlatformSubtypeDisplayValue } from '@/utils/reports/platform_subtype_utils'
import OriginalAssetPreview from '@/components/assets/original_asset_preview'

type Props = {
  loading: boolean
  onExitTapped: () => void
  handleOnCompletedAction: () => void
  client: any
}

type RetractionFormState = {
  isOpen: boolean
  enforcementRequest: any
}

const SpoofDetailModal: React.FC<Props> = ({
  loading,
  onExitTapped,
  handleOnCompletedAction,
  client,
}) => {
  const overlayRef = useRef<HTMLInputElement>()
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [selectedReportType] = useSelectedReportType()

  const [isEmployeeView] = useIsEmployeeView()

  const [reportFormOpen, setReportFormOpen] = useState(false)
  const [retractionFormState, setRetractionFormState] = useState<RetractionFormState>({
    isOpen: false,
    enforcementRequest: null,
  })
  const [showInternalView, setShowInternalView] = useState(false)
  const [isEnforcementUpload, setIsEnforcementUpload] = useState(false)

  const [shouldShowEnforcementDetails, setShouldShowEnforcementDetails] =
    useState(false)
  const [isTagEdited, setIsTagEdited] = useState(false)

  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)

  const { data: reportStatusChanges, loading: _fetchingStateChanges } =
    useGetReportChangesQuery({
      variables: {
        spoof_report_id: selectedSpoofReport.id,
      },
    })

  const {
    data: uploadedFiles,
    loading: _fetchingUploadedFiles,
    refetch: refetchFiles,
  } = useGetUploadedFilesQuery({
    variables: {
      spoof_report_id: selectedSpoofReport.id,
    },
  })

  const {
    data: alertLinksData,
    loading: _isAlertLinksRequestLoading,
    refetch: refetchAlertLinks,
  } = useGetAlertLinksByAlertIdQuery({
    variables: {
      alert_id: selectedSpoofReport.id,
    },
  })

  const alertLinks = alertLinksData?.alert_links ?? []

  const { data: enforcementRequestsData, loading: isEnforcementRequestLoading } =
    useGetEnforcementRequestsForSpoofingReportQuery({
      variables: { spoofing_report_id: selectedSpoofReport.id },
    })

  const platform: string = selectedSpoofReport?.platform?.name

  const productType = selectedSpoofReport?.platform?.product

  const {
    data: reportTags,
    loading: _reportTagsLoading,
    refetch: refetchTags,
  } = useGetTagsByReportQuery({
    variables: {
      report_id: selectedSpoofReport.id,
    },
  })

  const [updateSpoofingReportsMutation, { loading: updateReportLoading }] =
    useUpdateSpoofingReportsMutation()

  const delayExitTapped = () => {
    onExitTapped()
    if (isTagEdited) {
      setIsTagEdited(false)
      setTimeout(() => {
        delayRefetchedQueries(client, ['SearchSpoofingReports'])
      }, 3000)
    }
  }

  const getVisibleReportTags = (reportTags, isEmployeeView) => {
    if (isEmployeeView) {
      return reportTags?.report_tags ?? []
    } else {
      return (
        reportTags?.report_tags?.filter(
          (report_tag) => !report_tag?.tag?.is_internal,
        ) ?? []
      )
    }
  }

  const [visibleReportTags, setVisibleReportTags] = useState(
    getVisibleReportTags(reportTags, isEmployeeView),
  )

  useEffect(() => {
    setVisibleReportTags(getVisibleReportTags(reportTags, isEmployeeView))
  }, [reportTags, isEmployeeView])

  useEffect(() => {
    refetchTags()
    refetchFiles()
  }, [])

  const toast = useToast()

  const onSaveNotes = (notes) => {
    updateSpoofingReportsMutation({
      variables: {
        input: {
          report_ids: [selectedSpoofReport.id],
          update_source: ReportChangeEventSource.UI,
          notes: notes,
        },
      },
      update: (cache, { data: { update_reports_action } }) => {
        cache.modify({
          id: cache.identify(selectedSpoofReport),
          fields: {
            notes: () => update_reports_action?.updated_fields?.notes,
          },
        })
      },
      onCompleted: () => {
        toast({
          title: 'Notes saved.',
          description: 'Your notes have been successfully saved.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
      },
      onError: (err) => {
        toast({
          title: 'Error saving notes.',
          description: `Error: ${err.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })
  }

  const setReportStatus = (
    status,
    additionalOnComplete = () => {
      return
    },
  ) => {
    updateSpoofingReportsMutation({
      variables: {
        input: {
          report_ids: [selectedSpoofReport.id],
          update_source: ReportChangeEventSource.UI,
          report_status: status,
        },
      },
      update: (cache, { data: { update_reports_action } }) => {
        // give apollo cache the new values of updated fields
        cache.modify({
          id: cache.identify(selectedSpoofReport),
          fields: {
            report_status: () => update_reports_action?.updated_fields?.report_status,
          },
        })
      },
      onCompleted: () => {
        setReportFormOpen(false)
        handleOnCompletedAction()
        additionalOnComplete()
        delayRefetchedQueries(client, [
          'SearchSpoofingReportsCount',
          'GetEnforcementRequestsForSpoofingReport',
        ])
        toast({
          title: 'Queue state changed to ' + STATUS_TO_HEADER_LABEL[status],
          status: 'success',
          isClosable: true,
        })
      },
    })
  }

  if (loading) {
    return (
      <DetailModal handleClickOutside={delayExitTapped} overlayRef={overlayRef}>
        <Flex align="center" height="500px" justify="center" width="530px">
          <Spinner />
        </Flex>
      </DetailModal>
    )
  }

  if (showInternalView) {
    return (
      <DetailModal
        handleClickOutside={delayExitTapped}
        overlayRef={overlayRef}
        paddingTop="48px"
      >
        <DetailNavBar
          onBackPressed={() => setShowInternalView(false)}
          onExitTapped={delayExitTapped}
        />

        <SpoofDetailDebugView />
      </DetailModal>
    )
  }

  if (reportFormOpen) {
    return (
      <DetailModal handleClickOutside={delayExitTapped} overlayRef={overlayRef}>
        <EnforcementModal
          onClose={() => setReportFormOpen(false)}
          onCompleted={() => {
            setReportFormOpen(false)
            if (!isEnforcementUpload) {
              setReportStatus(ReportStatus.ENUM_REPORTED)
            }
            handleOnCompletedAction()
          }}
          platformOptions={getEnforcementPlatformOptions(
            productType,
            selectedSpoofReport.type,
          )}
          productType={productType}
          returnToTableView={delayExitTapped}
        />
      </DetailModal>
    )
  }

  if (retractionFormState.isOpen && retractionFormState.enforcementRequest) {
    return (
      <DetailModal handleClickOutside={delayExitTapped} overlayRef={overlayRef}>
        <RetractionModal
          enforcementRequest={retractionFormState.enforcementRequest}
          onClose={() =>
            setRetractionFormState({ isOpen: false, enforcementRequest: null })
          }
        />
      </DetailModal>
    )
  }

  const internalNavBarButtons = isEmployeeView ? (
    <IconButton
      _hover={{ background: DOPPEL_LIGHT_GREY }}
      aria-label="internal payload view toggle"
      bgColor={DOPPEL_INTERNAL_PURPLE}
      borderRadius="500px"
      color="white"
      icon={<IoMdEye />}
      onClick={() => setShowInternalView(!showInternalView)}
      size={'sm'}
    />
  ) : (
    <></>
  )

  const headerButtons = (
    <Flex align="start" justify="flex-end" pr="12px" pt="12px">
      <HStack>
        <AiSuggestionsButton
          isEmployeeView={isEmployeeView}
          platform={platform}
          selectedSpoofReport={selectedSpoofReport}
        />

        {isEmployeeView && (
          <SpoofChangeBrandButton orgId={selectedSpoofReport.organization.id} />
        )}

        {productType != ProductType.TELCO && <SpoofRequestScreenshotButton />}

        {internalNavBarButtons}

        <IconButton
          aria-label="back to database"
          borderRadius="500px"
          icon={<CloseIcon />}
          onClick={() => delayExitTapped()}
          size={'sm'}
        />
      </HStack>
    </Flex>
  )

  return (
    <DetailModal
      handleClickOutside={delayExitTapped}
      overlayRef={overlayRef}
      width="1200px"
    >
      {shouldShowEnforcementDetails ? (
        <EnforcementDetailModal
          isEmployeeView={isEmployeeView}
          onExitTapped={onExitTapped}
          reportId={selectedSpoofReport.id}
          setShouldShowEnforcementDetails={setShouldShowEnforcementDetails}
        />
      ) : (
        <Flex direction="column" width="100%">
          {headerButtons}

          <Flex justifyContent="space-evenly" pb="24px" pl="24px" pr="24px">
            {selectedReportType == ReportType.DARK_WEB &&
            DARK_WEB_TYPES.includes(selectedReportExposureType) ? (
              <SpoofDetailDarkWebPreview />
            ) : (
              <>
                <SpoofDetailImagePreview />

                <OriginalAssetPreview />
              </>
            )}
          </Flex>

          <Flex direction="column" padding="24px" paddingTop="6px">
            <Flex>
              <Flex direction="column" width="55%">
                <Heading as="h4" marginBottom={1} size="md">
                  Alert Details
                </Heading>

                <Text fontSize={12} marginBottom={2}>
                  {getTimestampDisplay(selectedSpoofReport.created_at)}
                </Text>

                <HStack marginBottom={5}>
                  <Tag fontSize={12} height="fit-content" width="fit-content">
                    {getExternalReportId(
                      selectedSpoofReport.organization?.abbr_name,
                      selectedSpoofReport.external_id,
                    )}
                  </Tag>

                  <Tag fontSize={12} height="fit-content" width="fit-content">
                    {selectedSpoofReport.type}
                  </Tag>

                  <Tag fontSize={12} height="fit-content" width="fit-content">
                    {toSnakeCase(
                      getStatusLabel(
                        selectedSpoofReport.report_status,
                        selectedReportType,
                      ),
                    )}
                  </Tag>

                  {selectedSpoofReport.spoof_status == Classification.ACTIVE &&
                    selectedSpoofReport.severity && (
                      <Tag
                        bg={getColorForSeverity(selectedSpoofReport.severity)}
                        fontSize={12}
                        height="fit-content"
                        width="fit-content"
                      >
                        {selectedSpoofReport.severity}
                      </Tag>
                    )}

                  <SpoofStatusTagWithMenu
                    classification={selectedSpoofReport.spoof_status as Classification}
                    loading={updateReportLoading}
                    onChange={(value: Classification) => {
                      updateSpoofingReportsMutation({
                        variables: {
                          input: {
                            report_ids: [selectedSpoofReport.id],
                            update_source: ReportChangeEventSource.UI,
                            report_classification: value,
                          },
                        },
                        update: (cache, { data: { update_reports_action } }) => {
                          // give apollo cache the new values of updated fields
                          cache.modify({
                            id: cache.identify(selectedSpoofReport),
                            fields: {
                              spoof_status: () =>
                                update_reports_action?.updated_fields?.spoof_status,
                            },
                          })
                        },
                      })
                    }}
                  />
                </HStack>

                <DetailRow
                  button={
                    <SpoofDetailReroutePlatformSubtypeButton
                      alert={selectedSpoofReport}
                    />
                  }
                  hasMaxTwoColumns={true}
                  key={'Platform'}
                  leftDetail={
                    <Text fontSize="xs" pr="2">
                      {getPlatformSubtypeDisplayValue(
                        selectedSpoofReport.platform_subtype?.name,
                      )}
                    </Text>
                  }
                  title={'Platform'}
                />

                <Divider />

                <DetailRow
                  button={
                    <SpoofDetailEditTagsButton
                      currentTags={visibleReportTags?.map((reportTag) => reportTag.tag)}
                      orgID={selectedSpoofReport.organization.id}
                      refetchTags={refetchTags}
                      reportID={selectedSpoofReport.id}
                      setIsTagEdited={setIsTagEdited}
                    />
                  }
                  hasMaxTwoColumns={true}
                  key={'Tags'}
                  leftDetail={
                    visibleReportTags.length > 0 ? (
                      <Flex>
                        {visibleReportTags.map((report_tag) => (
                          <DoppelTag
                            key={report_tag?.tag?.id}
                            tag={report_tag?.tag?.name}
                          />
                        ))}
                      </Flex>
                    ) : (
                      <Text fontSize="xs" pr="2">
                        None
                      </Text>
                    )
                  }
                  title={'Tags'}
                />

                <SpoofDetailDarkWebBreakdown />

                <SpoofDetailDomainData />

                <SocialMediaBreakdown />

                <SpoofDetailEcommerceBreakdown />

                <SpoofDetailUploader />

                <Flex flexWrap="wrap" justify={'flex-start'}>
                  <DownloadTdFile report_id={selectedSpoofReport.id} />
                </Flex>

                {/* Approvals not prioritized atm,  so commented out
              {isEmployeeView &&
                (selectedSpoofReport?.report_status ==
                  ReportStatus.ENUM_NEEDS_CONFIRMATION ||
                  selectedSpoofReport?.report_status == ReportStatus.ENUM_REPORTED ||
                  selectedSpoofReport?.report_status == ReportStatus.ENUM_RESOLVED ||
                  selectedSpoofReport?.report_status ==
                    ReportStatus.ENUM_APPROVED_DERIVATIVE) && (
                  <>
                    <Divider maxWidth="90%" />

                    <DetailRow
                      hasMaxTwoColumns={true}
                      leftDetail={
                        <>
                          <VStack align="flex-start" spacing={1}>
                            <ApprovalStatuses
                              approvals={selectedSpoofReport.approvals}
                            />
                          </VStack>
                        </>
                      }
                      title="Approvals"
                    />
                  </>
                )} */}

                <EnforcementStatuses
                  client={client}
                  enforcementRequests={enforcementRequestsData?.enforcement_requests}
                  isEnforcementRequestLoading={isEnforcementRequestLoading}
                  onEnforcementUpload={() => {
                    setIsEnforcementUpload(true)
                    setReportFormOpen(true)
                  }}
                  product={productType}
                  screenshots={selectedSpoofReport.report_screenshots}
                  setRetractionFormState={setRetractionFormState}
                  setShouldShowEnforcementDetails={setShouldShowEnforcementDetails}
                />
              </Flex>

              <Flex direction="column">
                <Flex flexWrap="wrap" justify={'flex-end'}>
                  <DetailNotes
                    notes={selectedSpoofReport?.notes}
                    onSave={onSaveNotes}
                  />
                </Flex>

                <Flex flexWrap="wrap" justify={'flex-end'}>
                  {getAllowedActionButtons(
                    selectedSpoofReport.report_status,
                    isEmployeeView,
                  ).map((status: ReportStatus) => (
                    <Box key={`${status}-button`}>
                      <ReportDetailButton
                        currentStatus={selectedSpoofReport.report_status}
                        reportAction={status}
                        setReportFormOpen={setReportFormOpen}
                        shouldShowLoadingState={true}
                        statusIsEnum
                        updateReportStatusMutation={(additionalOnComplete) => {
                          setReportStatus(status, additionalOnComplete)
                        }}
                      />
                    </Box>
                  ))}
                </Flex>
              </Flex>
            </Flex>

            <Flex width="100%">
              <AlertLinksTable
                alert={selectedSpoofReport}
                alertLinks={convertToInternalAlertLinks(alertLinks)}
                org={selectedSpoofReport.organization}
                refetchAlertLinks={refetchAlertLinks}
              />
            </Flex>

            <Flex width="100%">
              {uploadedFiles && (
                <UploadedFilesTable
                  isEmployeeView={isEmployeeView}
                  refetchFiles={refetchFiles}
                  spoofReportId={selectedSpoofReport.id}
                  uploadedFiles={uploadedFiles.uploaded_files}
                />
              )}
            </Flex>

            <Flex width="100%">
              {selectedSpoofReport.spoof_matches?.length > 0 && (
                <DetectionReasonsTable
                  match_attributions={
                    selectedSpoofReport.spoof_matches[0].match_attributions
                  }
                  ml_features={selectedSpoofReport.spoof_matches[0].ml_features}
                />
              )}
            </Flex>

            <Flex width="100%">
              {reportStatusChanges ? (
                <AuditLogTable stateChanges={reportStatusChanges} />
              ) : (
                <Spinner />
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
    </DetailModal>
  )
}

export default withApollo<Props>(SpoofDetailModal)
