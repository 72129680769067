import { Flex } from '@chakra-ui/react'
import React from 'react'
import { ReportType } from '@/utils/constants'
import DarkWebGenericReportInfo from '@/components/darkweb/dark_web_generic_report_info'
import DarkWebCreditCardInfo from '@/components/darkweb/dark_web_credit_card_info'
import DarkWebCredentialLeakInfo from '@/components/darkweb/dark_web_credential_leak_info'
import { useSelectedReportType } from '@/pages/reports'

export default function SpoofDetailDarkWebPreview() {
  const [selectedReportType] = useSelectedReportType()

  if (selectedReportType !== ReportType.DARK_WEB) {
    return <></>
  }

  return (
    <>
      <Flex
        alignItems="flex-start"
        flexDirection="column"
        height="300px"
        justifyContent="flex-start"
        overflowY={'scroll'}
        width="100%"
      >
        <DarkWebGenericReportInfo />

        <DarkWebCreditCardInfo />

        <DarkWebCredentialLeakInfo />
      </Flex>
    </>
  )
}
