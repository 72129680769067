import { Divider, Text } from '@chakra-ui/react'
import React from 'react'
import DetailRow from '../../report_detail/detail_row'

export default function SocialMediaDataBreakdown(socialMediaData) {
  return (
    <>
      {socialMediaData.type && (
        <>
          <Divider maxWidth="90%" />

          <DetailRow
            hasMaxTwoColumns={true}
            leftDetail={<Text fontSize={'xs'}>{socialMediaData.type}</Text>}
            title="Account Type"
          />
        </>
      )}

      {socialMediaData.profile_display_name && (
        <>
          <Divider maxWidth="90%" />

          <DetailRow
            hasMaxTwoColumns={true}
            leftDetail={
              <Text fontSize={'xs'}>{socialMediaData.profile_display_name}</Text>
            }
            title="Display Name"
          />
        </>
      )}

      {socialMediaData.external_id && (
        <>
          <Divider maxWidth="90%" />

          <DetailRow
            hasMaxTwoColumns={true}
            leftDetail={<Text fontSize={'xs'}>{socialMediaData.external_id}</Text>}
            title="External ID"
          />
        </>
      )}

      {socialMediaData && (
        <>
          <Divider maxWidth="90%" />

          <DetailRow
            hasMaxTwoColumns={true}
            leftDetail={
              <Text fontSize={'xs'}>
                {socialMediaData.num_followers?.toLocaleString()}
              </Text>
            }
            title="Follower Count"
          />
        </>
      )}

      {socialMediaData.profile_description && (
        <>
          <Divider maxWidth="90%" />

          <DetailRow
            hasMaxTwoColumns={true}
            leftDetail={
              <Text fontSize={'xs'}>{socialMediaData.profile_description}</Text>
            }
            title="Bio"
          />
        </>
      )}

      {socialMediaData.profile_display_url && (
        <>
          <Divider maxWidth="90%" />

          <DetailRow
            hasMaxTwoColumns={true}
            leftDetail={
              <Text fontSize={'xs'}>{socialMediaData.profile_display_url}</Text>
            }
            title="Bio URL"
          />
        </>
      )}
    </>
  )
}
