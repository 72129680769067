import { Divider, Text } from '@chakra-ui/react'
import React from 'react'
import DetailRow from '../../report_detail/detail_row'
import {
  extractDiscordIds,
  extractSubreddit,
  getTruncatedText,
} from '../../../utils/string_utils'
import moment from 'moment-timezone'
import DoppelLink from '../../report_detail/doppel_link'

const MAX_TEXT_LENGTH = 1000

function getPostParent(socialMediaPost) {
  const platform = socialMediaPost.platform
  if (platform === 'reddit') {
    return (
      <>
        {socialMediaPost?.url && (
          <>
            <Divider maxWidth="90%" />

            <DetailRow
              hasMaxTwoColumns={true}
              leftDetail={
                <Text fontSize={13}>{extractSubreddit(socialMediaPost.url)}</Text>
              }
              title="Subreddit"
            />
          </>
        )}
      </>
    )
  } else if (platform === 'discord') {
    const ids = extractDiscordIds(socialMediaPost.url)
    return (
      <>
        {ids?.serverId && (
          <>
            <Divider maxWidth="90%" />

            <DetailRow
              hasMaxTwoColumns={true}
              leftDetail={<Text fontSize={13}>{ids.serverId}</Text>}
              title="Discord Server ID"
            />
          </>
        )}

        {ids?.channelId && (
          <>
            <Divider maxWidth="90%" />

            <DetailRow
              hasMaxTwoColumns={true}
              leftDetail={<Text fontSize={13}>{ids.channelId}</Text>}
              title="Discord Channel ID"
            />
          </>
        )}
      </>
    )
  } else {
    return null
  }
}

export default function SocialMediaPostBreakdown(socialMediaPost) {
  return (
    <>
      {getPostParent(socialMediaPost)}

      <>
        <Divider maxWidth="90%" />

        <DetailRow
          hasMaxTwoColumns={true}
          leftDetail={
            <Text fontSize={13}>
              {socialMediaPost?.external_created_at
                ? // format timestamp
                  moment
                    .utc(socialMediaPost?.external_created_at)
                    .tz(moment.tz.guess())
                    .format('MM/DD/YY')
                : 'N/A'}
            </Text>
          }
          title="Post Creation Date"
        />
      </>

      <>
        <Divider maxWidth="90%" />

        <DetailRow
          hasMaxTwoColumns={true}
          leftDetail={
            <Text fontSize={13}>
              {getTruncatedText(socialMediaPost?.full_text, MAX_TEXT_LENGTH) || 'N/A'}
            </Text>
          }
          title="Post Content"
        />
      </>

      <>
        <Divider maxWidth="90%" />

        <DetailRow
          hasMaxTwoColumns={true}
          leftDetail={
            <Text fontSize={13}>{socialMediaPost?.num_upvotes || 'N/A'}</Text>
          }
          title="Upvotes"
        />
      </>

      {socialMediaPost?.extra_urls && socialMediaPost.extra_urls.length > 0 && (
        <>
          <Divider maxWidth="90%" />

          <DetailRow
            hasMaxTwoColumns={true}
            leftDetail={socialMediaPost?.extra_urls.map((url) => (
              <DoppelLink fontSize={12} href={url} key={url} name={url} />
            ))}
            title="Extra URLs"
          />
        </>
      )}
    </>
  )
}
