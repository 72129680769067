import { useToast, Input } from '@chakra-ui/react'
import React, { useState, useRef } from 'react'
import DoppelDefaultButton from './doppel_default_button'

export default function FileSelector({
  shouldIndicateSelectedFile,
  setFile,
  isInternal,
  acceptedFileTypes = [],
  acceptAllFileTypes = false,
  ...props
}) {
  const toast = useToast()
  const ref = useRef<HTMLInputElement>(null)
  const [buttonText, setButtonText] = useState('Select File')
  const handleFileChange = (event) => {
    const file: File = event.target.files[0] // Get the first file from the FileList
    if (acceptAllFileTypes || acceptedFileTypes.includes(file.type)) {
      if (shouldIndicateSelectedFile) {
        setButtonText(`Selected File: ${file.name}`)
      }
      setFile(file)
    } else {
      toast({
        title: 'Invalid file type',
        description: `Please select a valid file. Accepted file types are: ${acceptedFileTypes.join(
          ', ',
        )}`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  }
  const handleClick = (e) => {
    ref.current!.click()
  }
  return (
    <>
      <DoppelDefaultButton isInternal={isInternal} onClick={handleClick} {...props}>
        {buttonText}
      </DoppelDefaultButton>

      <Input
        onChange={handleFileChange}
        ref={ref}
        style={{ display: 'none' }}
        type="file"
      />
    </>
  )
}

export const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const getFileBase64Encoded = async (file) => {
  try {
    const fileAsBase64 = await readFileAsBase64(file)
    return fileAsBase64?.toString().split(',')[1]
  } catch (error) {
    return null
  }
}
