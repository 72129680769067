import {
  DOPPEL_BLACK,
  DOPPEL_CLICKABLE_GREY,
  DOPPEL_CLICKABLE_GREY_SHADE,
} from '@/utils/style'
import { Button } from '@chakra-ui/react'

function DownloadTdFile({ report_id }) {
  const handleDownload = () => {
    const uri = 'https://storage.googleapis.com/report_screenshots/td_kits.zip'
    const filename = 'td_kits.zip'

    // Create a temporary anchor element
    const a = document.createElement('a')
    a.href = uri
    a.download = filename

    // Programmatically click the anchor element to trigger the download
    a.click()
  }

  if (report_id !== 'd14cd66b-4756-4aba-85bf-3ebdb4dfab75') {
    return <></>
  }

  return (
    <Button
      _hover={{
        background: DOPPEL_CLICKABLE_GREY_SHADE,
        color: DOPPEL_BLACK,
      }}
      bgColor={DOPPEL_CLICKABLE_GREY}
      fontSize={14}
      marginTop={2}
      onClick={handleDownload}
      textColor={DOPPEL_BLACK}
    >
      Download File
    </Button>
  )
}

export default DownloadTdFile
