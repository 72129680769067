import React from 'react'
import { Flex, Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react'

export function SimpleHorizontalTable({ columnNames, data }) {
  return (
    <Flex alignItems="center" marginBottom={2}>
      <Table size="sm" variant="simple">
        <Thead>
          <Tr>
            {columnNames.map((columnName) => (
              <Th key={columnName}>{columnName}</Th>
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {data.length == 0 && <Text fontSize={13}>No rows to display</Text>}

          {data}
        </Tbody>
      </Table>
    </Flex>
  )
}

export function SimpleVerticalTable({ rowMappings, data }) {
  return (
    <Flex alignItems="center" marginBottom={2}>
      <Table size="sm" variant="simple">
        <Tbody>
          {rowMappings.map(({ name, key }) => (
            <Tr key={name}>
              <Th>{name}</Th>

              <Td>{data ? (data[key] ? data[key] : 'N/A') : 'N/A'}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  )
}
