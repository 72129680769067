import LinkExternal from '@/components/report_detail/link_external'
import { getTimestampDisplay } from '@/utils/time'
import { Flex, Heading, Td, Text, Th, Tr, useToast } from '@chakra-ui/react'
import { SpoofDetailModalTable } from '../detail_modal/spoof_detail_modal_table'
import FileSelector from '@/components/shared/file_selector_button'
import { useUpdateSpoofingReportsMutation } from '@/generated/graphql'
import DeleteButton from '@/components/shared/delete_button'
import { FileActionType, ReportChangeEventSource } from '@/generated/enums'

const UPLOADED_FILES_HEADER = 'Uploaded Files'

export function UploadedFilesTable({
  uploadedFiles,
  refetchFiles,
  spoofReportId,
  isEmployeeView,
}) {
  const toast = useToast()

  const [updateSpoofingReportsMutation] = useUpdateSpoofingReportsMutation()

  async function setFile(file) {
    let fileBase64Encoded = null
    if (file) {
      const reader = new FileReader()
      const fileAsBase64 = await new Promise<string | ArrayBuffer | null>(
        (resolve, reject) => {
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        },
      )
      fileBase64Encoded = fileAsBase64?.toString().split(',')[1]
      await updateSpoofingReportsMutation({
        variables: {
          input: {
            report_ids: [spoofReportId],
            update_source: ReportChangeEventSource.UI,
            file_name: file.name,
            file_to_upload: fileBase64Encoded,
            file_action: FileActionType.UPLOAD,
          },
        },
        onCompleted: (_) => {
          toast({
            title: 'Uploaded File Successfully',
            description: `Successfully uploaded file ${file.name} to this alert`,
            status: 'success',
            isClosable: true,
          })
        },
        onError: (error) => {
          toast({
            title: 'Failed to upload File',
            description: `Failed to upload File ${file.name} with error: ${error.message}`,
            status: 'error',
            isClosable: true,
          })
        },
      })
      refetchFiles()
    }
  }

  async function deleteFile(file_name) {
    await updateSpoofingReportsMutation({
      variables: {
        input: {
          report_ids: [spoofReportId],
          update_source: ReportChangeEventSource.UI,
          file_name,
          file_action: FileActionType.DELETE,
        },
      },
      onCompleted: (_) => {
        toast({
          title: 'Deleted File Successfully',
          description: `Successfully deleted file ${file_name} from this alert`,
          status: 'success',
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: 'Failed to delete File',
          description: `Failed to delete File ${file_name} with error: ${error.message}`,
          status: 'error',
          isClosable: true,
        })
      },
    })
    refetchFiles()
  }

  const header = (
    <>
      Uploaded Files
      <FileSelector
        acceptAllFileTypes={true}
        height="25px"
        isInternal={false}
        marginLeft="15px"
        setFile={setFile}
        shouldIndicateSelectedFile={false}
      />
    </>
  )

  if (uploadedFiles.length == 0) {
    return (
      <Flex direction="column" paddingBottom={4} width="100%">
        <Heading as="h4" mr={2} size="md">
          {header}
        </Heading>

        <Text fontSize={15} marginBottom={4} marginTop={4}>
          None
        </Text>
      </Flex>
    )
  }

  const columns = (
    <>
      <Th>File Name</Th>

      <Th>Uploaded By</Th>

      <Th>Uploaded At</Th>

      {isEmployeeView && <Th>Delete File</Th>}
    </>
  )

  const data = uploadedFiles.map((file, index) => (
    <Tr key={`state-change-${index}`}>
      <Td>
        <LinkExternal link={file?.gcs_path} name={file?.file_name} />
      </Td>

      <Td>{file?.user?.email}</Td>

      <Td>{getTimestampDisplay(file?.created_at, 'YYYY-MM-DD hh:mm a')}</Td>

      {
        <Td>
          <DeleteButton
            deleteFunc={() => {
              deleteFile(file?.file_name)
            }}
          />
        </Td>
      }
    </Tr>
  ))

  return (
    <SpoofDetailModalTable
      columns={columns}
      data={data}
      header={header}
    ></SpoofDetailModalTable>
  )
}
