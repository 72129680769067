import React, { useEffect, useRef } from 'react'
import {
  Heading,
  Flex,
  VStack,
  IconButton,
  Text,
  Divider,
  Spacer,
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useGetEnforcementDetailsBySpoofingReportIdQuery } from '../../generated/graphql'
import { EnforcementDetailRow } from './enforcement_detail_row'
import { EnforcementRequest } from './enforcement_email_adder'
import DetailModal from '@/components/shared_reports_table/detail_modal'
import { ENFORCEMENT_STATUS } from '@/utils/constants'

export default function EnforcementDetailModal({
  reportId,
  setShouldShowEnforcementDetails,
  onExitTapped,
  isEmployeeView,
}) {
  const overlayRef = useRef<HTMLInputElement>()
  const [enforcementRequests, setEnforcementRequests] = React.useState<
    EnforcementRequest[]
  >([])
  const {
    data: enforcementRequestsData,
    loading: isEnforcementRequestLoading,
    refetch: refetchEnforcementDetails,
  } = useGetEnforcementDetailsBySpoofingReportIdQuery({
    variables: { spoofing_report_id: reportId },
  })

  useEffect(() => {
    if (!enforcementRequestsData) return

    const enforcementRequestsMap: Record<string, EnforcementRequest> = (
      enforcementRequestsData?.spoofing_reports_by_pk?.enforcement_requests ?? []
    ).reduce((acc, enforcementRequest) => {
      const id = enforcementRequest?.id
      if (id) {
        acc[id] = {
          metadata: {
            spoofing_report_id: reportId,
            ...enforcementRequest,
          },
          enforcementRequestChanges: [],
        }
      }
      return acc
    }, {})

    enforcementRequestsData?.spoofing_reports_by_pk?.collection_report_changes.forEach(
      (change) => {
        const requestId = change?.enforcement_request_id
        if (requestId && enforcementRequestsMap[requestId]) {
          enforcementRequestsMap[requestId].enforcementRequestChanges.push(change)
        }
      },
    )

    Object.values(enforcementRequestsMap).forEach((enforcementRequest) => {
      enforcementRequest.enforcementRequestChanges.sort((a, b) => {
        const dateA = a?.timestamp ? new Date(a.timestamp).getTime() : 0 // default to 0 if timestamp is null
        const dateB = b?.timestamp ? new Date(b.timestamp).getTime() : 0
        return dateB - dateA // sort in descending order
      })
    })

    const enforcementRequests = Object.values(enforcementRequestsMap)
    enforcementRequests.sort((a, b) => {
      const dateA = a?.updatedAt ? new Date(a.updatedAt).getTime() : 0 // default to 0 if timestamp is null
      const dateB = b?.updatedAt ? new Date(b.updatedAt).getTime() : 0
      return dateB - dateA // sort in descending order
    })

    enforcementRequests?.filter(
      (enforcementRequest) =>
        enforcementRequest.metadata.status !== ENFORCEMENT_STATUS.CANCELED ||
        isEmployeeView,
    )

    setEnforcementRequests(enforcementRequests)
  }, [enforcementRequestsData])

  return (
    <DetailModal
      handleClickOutside={onExitTapped}
      height="800px"
      maxWidth="1200px"
      minWidth="1000px"
      overflowY="scroll"
      overlayRef={overlayRef}
    >
      <Flex direction="column" height="100%" width="100%">
        <Flex left="12px" position="absolute" top="12px" zIndex="2">
          <IconButton
            aria-label="back to alert details"
            borderRadius="500px"
            icon={<ArrowBackIcon />}
            onClick={() => setShouldShowEnforcementDetails(false)}
            size={'sm'}
          ></IconButton>
        </Flex>

        <VStack
          align={'left'}
          marginBottom={'50px'}
          marginTop={'70px'}
          mx={'50px'}
          spacing={30}
          width="90%"
        >
          <Heading size={'lg'} textAlign={'center'}>
            Enforcement Requests
          </Heading>

          {!isEnforcementRequestLoading &&
            !enforcementRequestsData &&
            !enforcementRequests && <Text>No enforcements to render</Text>}

          <Divider orientation="horizontal" sx={{ borderWidth: '2px' }} />

          <Spacer />

          {enforcementRequests.map((enforcementRequest) => {
            return (
              <>
                <EnforcementDetailRow
                  enforcementRequest={enforcementRequest}
                  isEmployeeView={isEmployeeView}
                  refetchEnforcementDetails={refetchEnforcementDetails}
                />

                <Divider orientation="horizontal" sx={{ borderWidth: '2px' }} />

                <Spacer />
              </>
            )
          })}
        </VStack>
      </Flex>
    </DetailModal>
  )
}
