import { Divider, Text, Box, Heading, VStack } from '@chakra-ui/react'
import React from 'react'
import { useSelectedSpoofReport } from '../../../hooks/spoof_report_table_filters'
import DetailRow from '../../report_detail/detail_row'
import { casingForDbFields } from '../../../utils/string_utils'

const extractDomainData = (selectedSpoofReport) => {
  if (selectedSpoofReport.first_domain_data.length > 0)
    return selectedSpoofReport.first_domain_data[0].full_url?.domain_data

  if (selectedSpoofReport.domain_data) return selectedSpoofReport.domain_data

  return null
}

export default function SpoofDetailDomainData() {
  const [selectedSpoofReport] = useSelectedSpoofReport()
  if (selectedSpoofReport.type != 'domain') {
    return null
  }

  const domainData = { ...extractDomainData(selectedSpoofReport) }
  delete domainData['__typename']
  let rawData = null
  if ('raw_data' in domainData) {
    rawData = JSON.parse(domainData['raw_data'])
    delete domainData['raw_data']
  }
  return (
    <>
      {Object.entries(domainData)
        .filter(([_, value]) => value != null)
        .map(([key, value]) => (
          <React.Fragment key={key}>
            <Divider mb={2} />

            <DetailRow
              hasMaxTwoColumns={true}
              key={key}
              leftDetail={<Text fontSize={13}>{String(value)}</Text>}
              title={casingForDbFields(key)}
            />
          </React.Fragment>
        ))}

      <SSLCert rawData={rawData} />

      <Nameservers rawData={rawData} />
    </>
  )
}

function Nameservers({ rawData }) {
  if (rawData == null) {
    return null
  }
  if (!rawData?.whois?.name_servers || !Array.isArray(rawData?.whois?.name_servers)) {
    return <></>
  }

  return (
    <>
      <Divider mb={2} />

      <DetailRow
        hasMaxTwoColumns={true}
        key={'Nameservers'}
        leftDetail={
          <VStack align="stretch">
            {rawData.whois.name_servers.map((nameServer, index) => (
              <Text fontSize="11" key={index} noOfLines={1} width="150px">
                {nameServer}
              </Text>
            ))}
          </VStack>
        }
        title={'Nameservers'}
      />
    </>
  )
}

function SSLCert({ rawData }) {
  if (rawData == null) {
    return null
  }
  if (!('ssl_cert' in rawData)) {
    return null
  }
  const data = rawData['ssl_cert']
  if (!data) {
    return <></>
  }

  const issuerInfo = data.issuer.map((item, index) => (
    <Text fontSize="11" key={index}>
      {item[0][0]}: {item[0][1]}
    </Text>
  ))

  return (
    <>
      <Divider mb={2} />

      <DetailRow
        hasMaxTwoColumns={true}
        key={'SSL Cert'}
        leftDetail={
          <Box>
            <VStack align="stretch">
              <Box>
                <Heading fontSize="11">Issuer</Heading>

                {issuerInfo}
              </Box>

              <Box>
                <Text fontSize="11">
                  <strong>Version:</strong> {data.version}
                </Text>

                <Text fontSize="11">
                  <strong>Serial Number:</strong> {data.serialNumber}
                </Text>

                <Text fontSize="11">
                  <strong>Not Before:</strong> {data.notBefore}
                </Text>

                <Text fontSize="11">
                  <strong>Not After:</strong> {data.notAfter}
                </Text>
              </Box>

              <Box>
                <Text fontSize="11">
                  <strong>OCSP:</strong> {data.OCSP[0]}
                </Text>

                <Text fontSize="11">
                  <strong>CA Issuers:</strong> {data.caIssuers[0]}
                </Text>
              </Box>
            </VStack>
          </Box>
        }
        title={'SSL Cert'}
      />
    </>
  )
}
