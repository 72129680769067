import React from 'react'
import { Tooltip, Input } from '@chakra-ui/react'

export default function TooltipTextInput({
  tooltipText,
  placeholder,
  value,
  onChange,
  ...props
}) {
  return (
    <Tooltip label={tooltipText} {...props}>
      <Input
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        type="text"
        value={value}
      />
    </Tooltip>
  )
}
