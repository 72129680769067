import {
  MenuItem,
  Input,
  useToast,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
} from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'
import { useBulkUpdateBrandForReportsMutation } from '../../../generated/graphql'
import {
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_LIGHT_GREY,
  DOPPEL_WHITE,
} from '../../../utils/style'
import { useGetBrandNamesQuery } from '../../../generated/graphql'
import { useUserID } from '../../../hooks/id_token_claims'
import DoppelAlertDialog from '../../shared/doppel_alert_dialog'
import { useSelectedSpoofReport } from '@/hooks/spoof_report_table_filters'
import { EditIcon } from '@chakra-ui/icons'
import { CollectionReportChangeType, ReportChangeEventSource } from '@/generated/enums'

export default function SpoofChangeBrandButton({ orgId }) {
  const [bulkUpdateBrandForReportsMutation, { data, loading, error }] =
    useBulkUpdateBrandForReportsMutation()
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredBrands, setFilteredBrands] = useState([])
  const [brand, setBrand] = useState(null)
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [userId] = useUserID()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  useEffect(() => {
    if (data) {
      toast({
        title: 'Success!',
        status: 'success',
        isClosable: true,
      })
    } else if (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        isClosable: true,
      })
    }
  }, [data, error])

  const toast = useToast()
  const { data: brandsData, loading: brandsLoading } = useGetBrandNamesQuery({
    variables: {
      where: { organization_id: { _eq: orgId } },
    },
  })

  useEffect(() => {
    if (!brandsLoading && brandsData) {
      const filtered = brandsData?.spoofing_entities.filter((brand) =>
        brand.entity_name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      setFilteredBrands(filtered)
    }
  }, [brandsData, searchQuery])

  const handleSearchChange = (event) => setSearchQuery(event.target.value)
  const handleBrandSelect = (brand) => {
    setBrand(brand)
    onOpen()
  }

  const confirmBrand = () => {
    if (brand) {
      bulkUpdateBrandForReportsMutation({
        variables: {
          ids: [selectedSpoofReport.id],
          brand_id: brand.id,
          audit_logs: [
            {
              new_value: brand.entity_name,
              old_value: selectedSpoofReport.spoofing_entity.entity_name,
              type: CollectionReportChangeType.BRAND,
              user_id: userId,
              source: ReportChangeEventSource.UI,
              spoof_report_id: selectedSpoofReport.id,
            },
          ],
        },
      })
      onClose()
    }
  }

  const menuListItems = [
    {
      key: 'searchInput',
      content: (
        <Input
          m={2}
          onChange={handleSearchChange}
          placeholder="Search brands..."
          variant="filled"
          width="90%"
        />
      ),
    },
    ...filteredBrands.map((brand) => ({
      key: brand.id,
      content: (
        <MenuItem onClick={() => handleBrandSelect(brand)}>
          {brand.entity_name}
        </MenuItem>
      ),
    })),
  ]

  return (
    <>
      <Menu>
        <MenuButton
          _hover={{ background: DOPPEL_LIGHT_GREY }}
          aria-label={'change brand'}
          as={IconButton}
          bgColor={DOPPEL_INTERNAL_PURPLE}
          borderRadius="500px"
          color={DOPPEL_WHITE}
          icon={<EditIcon />}
          size="sm"
        />

        {menuListItems && (
          <MenuList maxH="300px" overflowY="auto">
            {menuListItems.map((item, index) => (
              <React.Fragment key={item.key || index}>{item.content}</React.Fragment>
            ))}
          </MenuList>
        )}
      </Menu>

      <DoppelAlertDialog
        body={`Are you sure you want to update the brand from "${selectedSpoofReport?.spoofing_entity?.entity_name}" to "${brand?.entity_name}" ?`}
        cancelRef={cancelRef}
        confirmAction={confirmBrand}
        header="Brand Update"
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
