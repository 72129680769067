import React from 'react'
import { HStack, VStack, Tooltip } from '@chakra-ui/react'
import {
  Enforcement_Requests,
  useInsertEnforcementEmailMutation,
  useInsertCollectionReportChangesMutation,
} from '../../generated/graphql'
import {
  CollectionReportChangeType,
  ReportChangeEventSource,
  EnforcementEmailType,
  EnforcementEmailStatus,
} from '@/generated/enums'
import { useUserID } from '@/hooks/id_token_claims'
import DoppelDefaultButton from '../shared/doppel_default_button'
import DropdownSelector from '../shared/dropdown_selector'
import TooltipTextInput from '../shared/tooltip_text_input'

export type EnforcementRequest = {
  metadata: Enforcement_Requests
  enforcementRequestChanges: any[]
  updatedAt: string
}

export function EnforcementEmailAdder({
  enforcementRequest,
  refetchEnforcementDetails,
}: {
  enforcementRequest: EnforcementRequest
  refetchEnforcementDetails: () => void
}) {
  const [isAddEmailSelected, setIsAddEmailSelected] = React.useState(false)
  const [emailSender, setEmailSender] = React.useState('')
  const [emailSubject, setEmailSubject] = React.useState('')
  const [emailReceiver, setEmailReceiver] = React.useState('')
  const [enforcementEmailType, setEnforcementEmailType] = React.useState('')
  const [enforcementEmailStatus, setEnforcementEmailStatus] = React.useState('')
  const [emailTimestamp, setEmailTimestamp] = React.useState('')

  const [userID] = useUserID()

  const [insertEnforcementEmail] = useInsertEnforcementEmailMutation()
  const [insertCollectionReportChanges] = useInsertCollectionReportChangesMutation()

  const createManualEmail = () => {
    insertEnforcementEmail({
      variables: {
        sender: emailSender,
        receiver: emailReceiver,
        subject: emailSubject,
        type: enforcementEmailType,
        received_at: emailTimestamp,
      },
      onCompleted: (data) => {
        const enforcementEmailId = data.insert_enforcement_emails_one?.id
        insertCollectionReportChanges({
          variables: {
            type: CollectionReportChangeType.ENFORCEMENT_EMAIL_STATUS,
            old_value: null,
            new_value: enforcementEmailStatus,
            user_id: userID,
            spoof_report_id: enforcementRequest?.metadata?.spoofing_report_id,
            source: ReportChangeEventSource.UI,
            enforcement_request_id: enforcementRequest?.metadata?.id,
            enforcement_email_id: enforcementEmailId,
            timestamp: emailTimestamp,
          },
          onCompleted: (data) => {
            setIsAddEmailSelected(false)
            setEmailSender('')
            setEmailReceiver('')
            setEmailSubject('')
            setEnforcementEmailType('')
            setEnforcementEmailStatus('')
            setEmailTimestamp('')
            refetchEnforcementDetails()
          },
        })
      },
    })
  }

  const isCreateEmailDisabled = () => {
    if (
      [
        emailSender,
        emailSubject,
        emailReceiver,
        enforcementEmailType,
        enforcementEmailStatus,
        emailTimestamp,
      ].some((field) => !field || field === '' || field === null || field === undefined)
    ) {
      return true
    }

    if (!emailSender.includes('@') || !emailReceiver.includes('@')) {
      return true
    }

    return isNaN(new Date(emailTimestamp).getTime())
  }

  return (
    <div>
      {!isAddEmailSelected && (
        <DoppelDefaultButton
          onClick={() => setIsAddEmailSelected(!isAddEmailSelected)}
          size="xs"
        >
          Add Email
        </DoppelDefaultButton>
      )}

      {isAddEmailSelected && (
        <VStack align={'left'}>
          <HStack>
            <TooltipTextInput
              label="Email Sender"
              onChange={setEmailSender}
              placeholder={'Email Sender'}
              tooltipText="Email Sender must be an email address"
              value={emailSender}
            />

            <TooltipTextInput
              label="Email Receiver"
              onChange={setEmailReceiver}
              placeholder={'Email Receiver'}
              tooltipText="Email Receiver must be an email address"
              value={emailReceiver}
            />

            <TooltipTextInput
              label="Email Subject"
              onChange={setEmailSubject}
              placeholder={'Email Subject'}
              tooltipText="Email Subject should be a brief description of the email content"
              value={emailSubject}
            />

            <Tooltip label="Email Type indicates the nature of the email - likely 'reply' if you are logging a response to a platform's request for information">
              <span>
                <DropdownSelector
                  idField="email_type"
                  items={Object.values(EnforcementEmailType)}
                  onItemSelected={(value) => setEnforcementEmailType(value)}
                  selectedItem={enforcementEmailType}
                />
              </span>
            </Tooltip>

            <Tooltip label="Email Status indicates the status of the email - likely 'sent' if you are logging an email sent to a platform">
              <span>
                <DropdownSelector
                  idField="email_status"
                  items={Object.values(EnforcementEmailStatus)}
                  onItemSelected={(value) => setEnforcementEmailStatus(value)}
                  selectedItem={enforcementEmailStatus}
                />
              </span>
            </Tooltip>

            <TooltipTextInput
              onChange={setEmailTimestamp}
              placeholder="Email Timestamp"
              tooltipText="Email Timestamp should be a valid timestamp in UTC, which indicates when the email was sent or received"
              value={emailTimestamp}
            />
          </HStack>

          <HStack>
            <DoppelDefaultButton
              isDisabled={isCreateEmailDisabled()}
              onClick={createManualEmail}
              size="xs"
            >
              Add Email Record
            </DoppelDefaultButton>

            <DoppelDefaultButton onClick={() => setIsAddEmailSelected(false)} size="xs">
              Cancel
            </DoppelDefaultButton>
          </HStack>
        </VStack>
      )}
    </div>
  )
}
