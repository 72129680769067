import { Divider, Text } from '@chakra-ui/react'
import React from 'react'
import { useSelectedSpoofReport } from '../../../hooks/spoof_report_table_filters'
import DetailRow from '../../report_detail/detail_row'
import { DARK_WEB_TYPES } from '@/utils/reports/report_utils'

export default function SpoofDetailDarkWebBreakdown() {
  const [selectedSpoofReport] = useSelectedSpoofReport()
  if (!DARK_WEB_TYPES.includes(selectedSpoofReport.type)) {
    return null
  }
  const darkWebData = { ...selectedSpoofReport.spoof_matches[0].dark_web }
  const additionalData = {
    ...selectedSpoofReport.spoof_matches[0].dark_web.additional_data,
  }
  delete darkWebData['additional_data']
  delete darkWebData['__typename']
  delete darkWebData['body']
  delete darkWebData['title']
  delete darkWebData['id']

  return (
    <>
      {Object.entries({
        ...darkWebData,
        ...additionalData,
      })
        .filter(([_, value]) => value != null)
        .map(([key, value]) => (
          <>
            <Divider mb={2} />

            <DetailRow
              hasMaxTwoColumns={true}
              key={key}
              leftDetail={<Text fontSize={13}>{String(value)}</Text>}
              title={key}
            />
          </>
        ))}
    </>
  )
}
