import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { DarkWebPlatforms } from '@/utils/constants'
import { useAtom } from 'jotai'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import { useSelectedSpoofReport } from '@/hooks/spoof_report_table_filters'

export default function DarkWebCredentialLeakInfo() {
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const credentials = selectedSpoofReport?.spoof_matches?.[0]?.dark_web?.title

  if (
    selectedReportExposureType !== DarkWebPlatforms.CRED_LEAKS &&
    selectedSpoofReport.type !== DarkWebPlatforms.CRED_LEAKS
  ) {
    return <></>
  }

  const [username, password] = credentials?.split(':') || ['', '']

  return (
    <Box my={4} p={6}>
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Credentials Leaked:
      </Text>

      <Box mt={4} paddingLeft={5}>
        <Text fontSize="md" mb={2}>
          <strong>Username: </strong>

          {username}
        </Text>

        <Text fontSize="md">
          <strong>Password: </strong>

          {password}
        </Text>
      </Box>
    </Box>
  )
}
